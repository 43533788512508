import React from 'react';
import { navigate } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Layout from '../../components/Layout';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles((theme) => ({
  empty: {
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    width: '100%',
    height: '100vh',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > img': {
      width: 150
    }
  },
}));
export default () => {
  const classes = useStyles();

  React.useEffect(() => {
    setTimeout(() => {
      navigate('/consult/patientApp/chooseConsultant');
    }, 2000);
  }, []);

  return (
    <Layout hideAppBar>
      <Box p={2}>
        <div className={classes.empty}>
          <DoneIcon style={{ fontSize: 60 }}/>
          ยืนยันตัวตนสำเร็จ
        </div>
      </Box>
    </Layout>
  );
}

